import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC7_5seO3WWVTh7RP0BjGLJ2sanMkJEFoY",
  authDomain: "smart-pir-sensor.firebaseapp.com",
  databaseURL: "https://smart-pir-sensor-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-pir-sensor",
  storageBucket: "smart-pir-sensor.appspot.com",
  messagingSenderId: "599302873758",
  appId: "1:599302873758:web:912ea58cc838eeb058118f",
  measurementId: "G-2RQFKMMT5L"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    sn: Yup.string()
      .matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, 'Serial number must be a valid MAC address')
      .required('Serial number is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    sn: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const { email, firstName, lastName, password, sn } = data;
    try {
      const snapshot = await database.ref().once('value');
      const users = snapshot.val();

      if (users) {
        const macAddresses = Object.keys(users);
        if (macAddresses.includes(sn)) {
          const userRef = database.ref(`/${sn}`);
          const userSnapshot = await userRef.once('value');
          const userData = userSnapshot.val();
          if (userData && userData.email && userData.email !== email) {
            // Email already exists, display an alert
            toast.warn(`Email ${email} already exists`);
            return;
          }
          if (userData && userData.password && password && userData.password !== password) {
            console.log(`Password is not updated for existing user.`);
            // Display an error message or take appropriate action
            toast.warn(`user already exists`);
            return;
          }
          await userRef.update({
            email,
            firstName,
            lastName,
            password,
          })
          toast.success(`Successfully register`);
          navigate('/login');
        } else {
          console.log(`User with MAC address ${sn} does not exist.`);
          // User with MAC address does not exist, display an error message or take appropriate action
        }
      } else {
        toast.warn(`No user found`);
      }
    } catch (error) {
      console.error('Error accessing MAC addresses:', error);
      toast.warn(`Error accessing MAC addresses:`);
    }
  };



  return (

    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <RHFTextField name="email" label="Email address" />
        <RHFTextField name="sn" label="Serial number" placeholder="Back of product, e.g., 48:E7:29:49:3C:44" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
