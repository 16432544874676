import { useState, useEffect } from 'react';
import axios from 'axios';
import { formatDistanceToNow, parseISO, isValid,subMinutes } from 'date-fns';
import io from 'socket.io-client';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'employeeid', label: 'Device ID', alignRight: false },
  { id: 'time', label: 'Date', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'motion', label: 'Motion', alignRight: false },
  { id: 'co2', label: 'CO2', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_1 = [
  { id: 'employeeid', label: 'Device ID', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: '' },
];

const Dashboard = () => {
  const [userList, setUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterType, setFilterType] = useState('all');

  // useEffect(() => {
  //   axios.get('https://cnybyppy8l.execute-api.us-east-1.amazonaws.com/production/employees')
  //     .then((response) => {
  //       setUserList(response.data.employees || []);
  //       setFilteredUsers(response.data.employees || []);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

   useEffect(() => {
    const fetchData = () => {
      axios.get('https://cnybyppy8l.execute-api.us-east-1.amazonaws.com/production/employees')
        .then((response) => {
          setUserList(response.data.employees || []);
          setFilteredUsers(response.data.employees || []);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    fetchData();

    const socket = io('https://cnybyppy8l.execute-api.us-east-1.amazonaws.com/production/employees'); // Replace with your WebSocket server URL

    socket.on('update', (newData) => {
      setUserList(newData.employees || []);
      setFilteredUsers(newData.employees || []);
    });

    return () => {
      socket.disconnect(); // Clean up WebSocket connection on component unmount
    };
  }, []);


  useEffect(() => {
    console.log('filteredUsers:', filteredUsers);
    console.log('Type of filteredUsers:', typeof filteredUsers);
  }, [filteredUsers]);

  const handleFilterByName = (event) => {
    const { value } = event.target;
    setFilterName(value);
    const filtered = userList.filter(user => user.firstName.toLowerCase().includes(value.toLowerCase()));
    setFilteredUsers(filtered);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    // Sorting function for date-time
    const sorted = [...filteredUsers].sort((a, b) => {
      const aTime = parseISO(a.deviceData.time);
      const bTime = parseISO(b.deviceData.time);
      if (isAsc) {
        return aTime - bTime;
      }
      return bTime - aTime;
    });

    setFilteredUsers(sorted);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const groupByEmployeeId = (data) => {
    return data.reduce((acc, item) => {
      const { employeeid } = item;
      if (!acc[employeeid]) {
        acc[employeeid] = [];
      }
      acc[employeeid].push(item);
      return acc;
    }, {});
  };

  const latestDataForEachEmployee = () => {
    const groupedData = groupByEmployeeId(filteredUsers);
    const latestData = Object.values(groupedData).map(group => group[group.length - 1]);
    return latestData;
  };


  const latestFilteredUsers = latestDataForEachEmployee();
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - latestFilteredUsers.length) : 0;

  const isUserNotFound = latestFilteredUsers.length === 0;
  const filterUniqueEmployeeIds = (data) => {
    const uniqueIds = new Set();
    return data.filter((row) => {
      const { deviceData } = row;
      const { employeeid } = deviceData || {};
      if (!uniqueIds.has(employeeid)) {
        uniqueIds.add(employeeid);
        return true;
      }
      return false;
    });

  };





  const now = new Date();
  const uniqueEmployeeIds = new Set();
  const filteredUsersNew = latestFilteredUsers.filter((row) => {
  const { deviceData } = row;
  if (!deviceData || !deviceData.time) {
    return false;
  }
  const parsedTime = parseISO(deviceData.time);
  if (!isValid(parsedTime)) {
    return false;
  }
  if (parsedTime >= subMinutes(now, 5)) {
    if (!uniqueEmployeeIds.has(deviceData.employeeid)) {
      uniqueEmployeeIds.add(deviceData.employeeid);
      return true;
    }
  }
  return false;
});




  return (
    <>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User Device
        </Typography>
      </Stack>

      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <div style={{ overflowX: 'auto' }}>
            <TableContainer sx={{ minWidth: 1080 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_1}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
  {filterUniqueEmployeeIds(latestFilteredUsers).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
    const { deviceData, firstName, lastName, email } = row;
    const { employeeid } = deviceData || {};  // Added check for device_data existence
    const isItemSelected = selected.indexOf(firstName) !== -1;

    return (
      <TableRow
        hover
        key={employeeid}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onChange={(event) => handleClick(event, deviceData.firstName)}
          />
        </TableCell>
        <TableCell align="left">{employeeid}</TableCell>
        <TableCell align="left">{deviceData.firstName}</TableCell>
        <TableCell align="left">{deviceData.lastName}</TableCell>
        <TableCell align="left">{deviceData.email}</TableCell>
        <TableCell align="left">{deviceData.phone}</TableCell>
        <TableCell align="right">
          <UserMoreMenu />
        </TableCell>
      </TableRow>
    );
  })}
  {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows }}>
      <TableCell colSpan={8} />
    </TableRow>
  )}
</TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={latestFilteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          USER LOG
        </Typography>
      </Stack>

      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <div style={{ overflowX: 'auto' }}>
            <TableContainer sx={{ minWidth: 1080 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {latestFilteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { deviceData, firstName, lastName, email } = row;
                    const { time, motion, co2, employeeid } = deviceData || {};  // Added check for device_data existence
                    const isItemSelected = selected.indexOf(firstName) !== -1;
                    const parsedTime = parseISO(time);
                    const timeValid = isValid(parsedTime);
                    const [date, timeOnly] = time ? time.split("T") : ["N/A", "N/A"];

                    return (
                      <TableRow
                        hover
                        key={employeeid}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, firstName)}
                          />
                        </TableCell>
                        <TableCell align="left">{employeeid}</TableCell>
                        <TableCell align="left">{date}</TableCell> {/* Display the date */}
                        <TableCell align="left">{timeOnly}</TableCell> {/* Display the time */}
                        <TableCell align="left">{motion || 'N/A'}</TableCell>
                        <TableCell align="left">{co2 || 'N/A'}</TableCell>
                        <TableCell align="left">{firstName}</TableCell>
                        <TableCell align="left">{lastName}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="right">
                          <UserMoreMenu />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={latestFilteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
    <Container>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
       Trigger Devices
      </Typography>
    </Stack>

    <Card>
      <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <div style={{ overflowX: 'auto' }}>
          <TableContainer sx={{ minWidth: 1080 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={filteredUsersNew.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsersNew.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { deviceData, firstName, lastName, email } = row;
                  const { time, motion, co2, employeeid } = deviceData || {};
                  const isItemSelected = selected.indexOf(firstName) !== -1;
                  const parsedTime = parseISO(time);
                  const timeValid = isValid(parsedTime);

                  return (
                    <TableRow
                      hover
                      key={employeeid}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, firstName)}
                        />
                      </TableCell>
                      <TableCell align="left">{employeeid}</TableCell>
                      <TableCell align="left">
                       {time}
                      </TableCell>
                      <TableCell align="left">{motion || 'N/A'}</TableCell>
                      <TableCell align="left">{co2 || 'N/A'}</TableCell>
                      <TableCell align="left">{firstName}</TableCell>
                      <TableCell align="left">{lastName}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="right">
                        <UserMoreMenu />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsersNew.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  </Container>
    </>
  );
};

export default Dashboard;
