import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import LoginUser from './pages/Loginuser';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Role from './pages/Rolemanagment';
import State from './pages/State';
import City from './pages/City';
import Subcity from './pages/SubCity';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import ProtectedRoute from './sections/auth/ProtectedRoute'; // Adjust the path according to your project structure

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        // <ProtectedRoute>
          <DashboardLayout />
        // </ProtectedRoute>
      ),
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'role', element: <Role /> },
        { path: 'state', element: <State /> },
        { path: 'city', element: <City /> },
        { path: 'subcity', element: <Subcity /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'loginuser',
      element: <LoginUser />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
