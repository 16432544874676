import numeral from 'numeral';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC7_5seO3WWVTh7RP0BjGLJ2sanMkJEFoY",
  authDomain: "smart-pir-sensor.firebaseapp.com",
  databaseURL: "https://smart-pir-sensor-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-pir-sensor",
  storageBucket: "smart-pir-sensor.appspot.com",
  messagingSenderId: "599302873758",
  appId: "1:599302873758:web:912ea58cc838eeb058118f",
  measurementId: "G-2RQFKMMT5L"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export async function readUserData(macAddress) {
  const snapshot = await database.ref(`users/${macAddress}`).once('value');
  return snapshot.val();
}

export async function displayUserDataInDiv(macAddress, divId) {
  const userData = await readUserData(macAddress);
  if (userData) {
    console.log('User Data:', userData);
    const { id, status, switch: switchStatus, heartbeat } = userData;
    return { id, status, switchStatus, heartbeat };
  }
}



export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

// Example of writing data to Firebase database
export function writeToFirebase(data) {
  database.ref('your_database_path').set(data);
}

// Example of reading data from Firebase database
export async function readFromFirebase() {
  const snapshot = await database.ref('your_database_path').once('value');
  return snapshot.val();
}
