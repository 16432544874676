import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Switch, Button } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Page from '../components/Page';

const firebaseConfig = {
  apiKey: "AIzaSyC7_5seO3WWVTh7RP0BjGLJ2sanMkJEFoY",
  authDomain: "smart-pir-sensor.firebaseapp.com",
  databaseURL: "https://smart-pir-sensor-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-pir-sensor",
  storageBucket: "smart-pir-sensor.appspot.com",
  messagingSenderId: "599302873758",
  appId: "1:599302873758:web:912ea58cc838eeb058118f",
  measurementId: "G-2RQFKMMT5L"
};

firebase.initializeApp(firebaseConfig);

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginUser() {
  const { state } = useLocation();
  const userId = state;
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [switchValue, setSwitchValue] = useState('ON');

  const handleSwitchChange = async () => {
    console.log('Switch toggled');
    try {
      const userRef = firebase.database().ref(`/${userId}`);
      const newSwitchValue = !switchValue; // Toggle the switch value

      // Update the switch value in the database
      await userRef.update({ switch: newSwitchValue ? 'ON' : 'OFF' });

      // Update the local state to reflect the new switch value
      setSwitchValue(newSwitchValue);
      console.log('Switch value updated:', newSwitchValue);
    } catch (error) {
      console.error('Error updating switch value:', error);
    }
  };




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const snapshot = await firebase.database().ref(`/${userId}`).once('value');
        const userData = snapshot.val();
        setUserData(userData);
        if (userData?.switch) {
          setSwitchValue(userData.switch === 'ON');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, [userId]);

  const handleLogout = () => {
    setUserData(null);
    navigate('/login');
  };
  return (
    <Page title="LoginUser">
      <RootStyle>
        <Container maxWidth="sm" style={{border:'2px solid black', marginTop:'10px',marginBottom:'10px', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
          {console.log(userData)}
          <ContentStyle >
            <Typography variant="h4" gutterBottom style={{padding:'20px'}}>
              Welcome {userData?.firstName} {userData?.lastName}!
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{margin:'20px'}}>
              <div> Email:<span style={{boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',background:'#E4E5EC', padding:'5px',margin:'5px', }}>{userData?.email}</span></div>
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{margin:'20px'}}>
            <div> Password: <span style={{boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',background:'#E4E5EC', padding:'5px',margin:'5px', }}>{userData?.password}</span></div>
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{margin:'20px'}}>
            {userData?.status && (
                <>
              <div>  Status:<span style={{border:'2px solid red', padding:'5px',margin:'5px', borderRadius:'5px'}}>{JSON.parse(userData.status)["Last update"]} </span><span style={{background:'#F6DADC',color:'#B72136', padding:"4px", borderRadius:'5px'}}>{JSON.parse(userData.status).Status}</span></div>
                </>
              )}
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{margin:'20px'}}>
              Switch:
              <Switch
                checked={switchValue}
                onChange={handleSwitchChange}
                name="switch"
                inputProps={{ 'aria-label': 'Switch' }}
              />
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{margin:'20px'}}>
              <div> Heartbeat:<span style={{boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',background:'#E4E5EC', padding:'5px',margin:'5px' }}>{userData?.heartbeat}</span></div>
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>This is from your home sensor SR ID - {userData?.id}</Typography>
            <Button onClick={handleLogout} variant="outlined" color="error">Logout</Button>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
