import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const State = () => {
  return (
    <>
     <div style={{ background:'#3D7BD7', padding:'20px', borderRadius:'5px', boxShadow:'7px 10px 2px rgba(208,255,255, 0.7)'}}>
    <h2 style={{color:'white'}}>State Device Managment</h2>
    </div>
    </>
  )
}

export default State