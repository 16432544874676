// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),

  },
  {
    title: 'Role-managment',
    path: '/dashboard/role',
    icon: getIcon('majesticons:repeat-circle-line'),
  },
  {
    title: 'All user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  // {
  //   title: 'State devices',
  //   path: '/dashboard/state',
  //   icon: getIcon('majesticons:battery-full-line'),
  // },
  // {
  //   title: 'City device',
  //   path: '/dashboard/city',
  //   icon: getIcon('majesticons:battery-half-line'),
  // },
  // {
  //   title: 'Sub-City Devices',
  //   path: '/dashboard/subcity',
  //   icon: getIcon('majesticons:battery-low-line'),
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
